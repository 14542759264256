

import AppTitle from '@/components/AppTitle.vue'
import Notification from '@/components/Notification.vue'
import { Component, Vue } from 'vue-facing-decorator'
import { PollService } from '@/api/services/pollService'
import { Poll, PollPaginated } from '@/api/models/poll'
import { NotificationType } from '@/components/Notification.vue'
@Component({
  components: {
    AppTitle,
    Notification
  }
})
export default class MyContributions extends Vue {

  pollsPaginated: PollPaginated = new PollPaginated();
  NotificationType = NotificationType;

  get getPollsBySearch(): Poll[] {
    return this.pollsPaginated.content;
  }

  async mounted(): Promise<void> {
    await this.fetchPollsPaginated();
  }

  async fetchPollsPaginated(page = 1): Promise<void> {
    this.pollsPaginated = await PollService.getAllPollsContributedByUser(page);
  }

  async onPaginationChange(page: number): Promise<void> {
    await this.fetchPollsPaginated(page);
  }

  onPollRedirect(poll: Poll): void {
    this.$router.push({ name: 'poll', params: { id: poll.id } })
  }

}

