import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_description_banner = _resolveComponent("home-description-banner")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "pa-0"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.descriptions, (description, index) => {
        return (_openBlock(), _createBlock(_component_home_description_banner, {
          key: index,
          "item-text": description.itemText,
          "item-title": description.itemTitle,
          "md-height": description.mdHeight,
          "sm-height": description.smHeight,
          "color-hex": description.colorHex,
          "image-path": description.imagePath,
          "css-index": description.cssIndex,
          left: description.left
        }, null, 8, ["item-text", "item-title", "md-height", "sm-height", "color-hex", "image-path", "css-index", "left"]))
      }), 128))
    ]),
    _: 1
  }))
}