import axios, { Axios, AxiosInstance } from 'axios'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

const axiosInstance: AxiosInstance = axios.create({
 // baseURL: 'http://localhost:8089/api-node/'
  // baseURL: 'http://localhost:8088/api-spring/'
   baseURL: 'https://ljns.fr:8512/api-spring/'
})

axiosInstance.interceptors.request.use(
  async config => {
    const token = cookies.get('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config
  },
  error => {
    Promise.reject(error)
  })

axiosInstance.interceptors.response.use(
  async response => {
    return response
  },
  async error => {
    if (process.env.VUE_APP_LOGGING_ENABLED === 'true') {
      console.log('axios error: ', JSON.stringify(error))
    }
    console.log(error)
    if (error.response.status === 401 && window.location.pathname !== '/login') {
      window.location.href = '/login'
      cookies.remove('token')
    }
    throw error
  }
)

export default axiosInstance
