import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14e3e0c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "white--text" }
const _hoisted_2 = { class: "white--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createBlock(_component_v_footer, { color: "blue-grey-darken-3" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "full-width" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                class: "text-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    href: "https://www.linkedin.com/in/sami-lejeune/",
                    icon: "",
                    class: "mx-4 white--text"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-linkedin")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    href: "https://github.com/SamiLejeune",
                    icon: "",
                    class: "mx-4 white--text"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-github")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_divider)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "full-width" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                class: "d-flex justify-center align-center"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_1, " BroPoll - " + _toDisplayString(new Date().getFullYear()), 1),
                  _createVNode(_component_v_badge, {
                    color: _ctx.apiStatus ? 'success' : 'error',
                    class: "ml-10"
                  }, {
                    badge: _withCtx(() => [
                      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.apiStatus ? 'API en ligne' : 'API hors ligne'), 1)
                    ]),
                    _: 1
                  }, 8, ["color"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}