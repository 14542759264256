

import { Component, Vue, Prop } from 'vue-facing-decorator'

@Component
export default class AppTitle extends Vue {

  @Prop({
    type: String,
    required: true
  }) title!: string
}
