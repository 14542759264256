

import AppTitle from '@/components/AppTitle.vue'
import Notification from '@/components/Notification.vue'
import { Component, Vue } from 'vue-facing-decorator'
import { PollService } from '@/api/services/pollService'
import { Poll, PollPaginated } from '@/api/models/poll'
import { NotificationType } from '@/components/Notification.vue'
import { VDatePicker } from 'vuetify/labs/VDatePicker'
import {GiftService} from "@/api/services/giftService";
import {Gift} from "@/api/models/gift";

@Component({
  components: {
    AppTitle,
    Notification,
    VDatePicker
  }
})
export default class MyPolls extends Vue {

  pollsPaginated: PollPaginated = new PollPaginated();
  NotificationType = NotificationType;
  isCreatingPoll = false;
  pollToCreate: Poll = new Poll();
  fileToUpload: null | File = null;

  get getPollsBySearch(): Poll[] {
    return this.pollsPaginated.content;
  }

  get minDateEqualsNow(): string {
    return new Date().toISOString().substr(0, 10);
  }

  async mounted(): Promise<void> {
    await this.fetchPollsPaginated();
  }

  async fetchPollsPaginated(page = 1): Promise<void> {
    this.pollsPaginated = await PollService.getAllPollsCreatedByUser(page);
  }

  openDialogCreation() {
    this.pollToCreate = new Poll()
    this.isCreatingPoll = true
  }

  onPollRedirect(poll: Poll): void {
    this.$router.push({ name: 'poll', params: { id: poll.id } })
  }

  onFileUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input && input.files && input.files.length > 0) {
      const file = input.files[0];
      this.readFileAsBase64(file);
    }
  }

  async onPaginationChange(page: number): Promise<void> {
    await this.fetchPollsPaginated(page);
  }

  readFileAsBase64(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      let base64Data = reader.result as string;
      base64Data = base64Data.replace(/^data:image\/[a-z]+;base64,/, "");
      this.pollToCreate.photo = base64Data;
    };
    reader.readAsDataURL(file);
  }

  async createPoll(): Promise<void> {
    this.isCreatingPoll = false
    await PollService.createPoll(this.pollToCreate)
    await this.fetchPollsPaginated();
  }

}

