import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a7d1cd76"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification__message py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (!_ctx.closed)
        ? (_openBlock(), _createBlock(_component_v_card, {
            key: 0,
            "max-width": _ctx.minWidth,
            "min-height": _ctx.minHeight,
            class: "d-flex flex-column justify-space-between",
            color: "grey-darken-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "1" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        size: "s",
                        color: _ctx.colorByType,
                        "aria-hidden": "false",
                        class: "pt-5 pl-5"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.iconByType), 1)
                        ]),
                        _: 1
                      }, 8, ["color"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "10" }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.message), 1)
                    ]),
                    _: 1
                  }),
                  (_ctx.closable)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        cols: "1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            size: "s",
                            "aria-hidden": "false",
                            class: "cursor-pointer pr-3 pt-5",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closed = true))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" mdi-close ")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              (!_ctx.permanent)
                ? (_openBlock(), _createBlock(_component_v_progress_linear, {
                    key: 0,
                    "model-value": _ctx.progression,
                    color: _ctx.colorByType
                  }, null, 8, ["model-value", "color"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["max-width", "min-height"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}