import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue3-cookies'
import Dayjs from "dayjs";
import moment from "moment-timezone";
import { createPinia } from 'pinia'

const pinia = createPinia()
const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(pinia)
  .use(VueCookies);

app.config.errorHandler = (err, vm, info) => {
  console.error('Erreur d\'exécution:', err, info);
};

app.config.globalProperties.$filters = {
  formatLocaleDateTime (value: string) {
    return moment(value).utc().tz('Europe/Paris').format("DD/MM/YYYY à HH:mm");
  }
}

app.mount('#app')
