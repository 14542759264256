
import BpFooter from '@/components/BpFooter.vue'
import { useCookies } from 'vue3-cookies'
import {Component, Setup, Vue} from 'vue-facing-decorator'
const { cookies } = useCookies()
import { useApiModuleStore } from '@/store/apiModule'
import {storeToRefs} from "pinia";

@Component({
  components: {
    BpFooter
  }
})
export default class App extends Vue {

  @Setup(() => {
    const apiModule = useApiModuleStore()
    const { apiStatus } = storeToRefs(apiModule);
    return apiStatus;
  })
  apiStatus!: boolean

  hasAcceptedCookie = false;

  async mounted(): Promise<void> {
    const apiModule = useApiModuleStore()
    await apiModule.ping();
    this.hasAcceptedCookie = !!cookies.get('cookieBanner')
  }

  get isConnected () {
    return cookies.get('token')
  }

  redirectHome () {
    this.$router.push('/')
  }

  redirectMyPolls () {
    this.$router.push('/my-polls')
  }

  redirectMyContributions () {
    this.$router.push('/my-contributions')
  }

  redirectLogin() {
    this.$router.push('/login')
  }

  logout () {
    cookies.remove('token')
    cookies.remove('user')
    window.location.reload()
  }

  acceptCookie() {
    cookies.set('cookieBanner','true');
    this.hasAcceptedCookie = true
  }
}
