
import { AuthService } from '@/api/services/authService'
import { useCookies } from 'vue3-cookies'
import { Component, Ref, Vue } from 'vue-facing-decorator'
import { Auth } from '@/api/models/auth'
const { cookies } = useCookies()

@Component
export default class Login extends Vue {

  @Ref() readonly form!: HTMLFormElement

  order = 1
  email = ''
  password = ''
  error = ''
  firstName = ''
  lastName = ''

  mounted (): void {
    if (cookies.get('token')) {
      this.$router.push('/')
    }
  }
  get isEmailValid(): boolean {
    return this.email !== '' && /.+@.+\..+/.test(this.email)
  }

  get isPasswordValid(): boolean {
    return this.password !== '' && this.password.length >= 2
  }

  get isDisabled(): boolean {
    return !this.isEmailValid || !this.isPasswordValid || (this.order === 0 && !this.firstName) || (this.order === 0 && !this.lastName)
  }

  changeOrder (): void {
    this.order = this.order === 0 ? 1 : 0
    this.email = ''
    this.password = ''
    this.form.resetValidation()
  }

  emailRules: Function[] = [
    (v: string) => {
      return !!v || 'L\'email est requis'
    },
    (v: string) => {
      return /.+@.+\..+/.test(v) || 'L\'email doit être valide'
    },
  ]

  passwordRules: Function[] = [
    (v: string) => {
      return !!v || 'Le mot de passe est requis'
    },
    (v: string) => {
      return v.length >= 2 || 'Le mot de passe doit contenir au moins 2 caractères'
    },
  ]

  async submit (): Promise<void> {
    try {
      let answer: Auth;
      if (this.order === 0) {
        answer = await AuthService.register(this.email, this.password, this.firstName, this.lastName)
        this.order = 1;
      } else {
        answer = await AuthService.login(this.email, this.password)
        cookies.set('token', answer.token)
        cookies.set('user', answer.user.id)
        window.location.reload()
      }

    } catch (error: any) {
      this.error = error.response.data.message
    }
  }
}
