
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING'
}

@Component
export default class Notification extends Vue {

  @Prop({
    type: String,
    required: true
  }) message!: string

  @Prop({
    type: String,
    required: true
  }) type!: NotificationType

  @Prop({
    type: Boolean,
    default: false
  }) permanent!: boolean

  @Prop({
    type: Boolean,
    default: true
  }) closable!: boolean

  @Prop({
    type: Number,
    default: 500
  }) minWidth!: number

  @Prop({
    type: Number,
    default: 75
  }) minHeight!: number

  progression = 100
  closed = false;

  get colorByType(): string {
    switch (this.type) {
      case NotificationType.SUCCESS:
        return 'success'
      case NotificationType.ERROR:
        return 'error'
      case NotificationType.INFO:
        return 'info'
      case NotificationType.WARNING:
        return 'warning'
      default:
        return 'info'
    }
  }

  get iconByType(): string {
    switch (this.type) {
      case NotificationType.SUCCESS:
        return 'mdi-check-circle'
      case NotificationType.ERROR:
        return 'mdi-alert-circle'
      case NotificationType.INFO:
        return 'mdi-information'
      case NotificationType.WARNING:
        return 'mdi-alert'
      default:
        return 'mdi-information'
    }
  }

  mounted(): void {
    if (this.permanent) {
      return
    }
    setInterval(() => {
      if (this.progression > 0) {
        this.progression -= 0.25
      } else {
        this.closed = true
      }
    }, 40);
  }

}

