import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20add7e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "switcher__title pa-3 pa-md-0" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "switcher__text mt-5" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "mt-15 pa-3 pa-md-0" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "px-6"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 0,
  class: "text-center mt-5 text-red"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "full-height d-flex align-center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "9"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                "min-height": "500px",
                height: "100%",
                elevation: "1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, {
                    class: "full-height",
                    "no-gutters": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "3",
                        class: "d-flex flex-column justify-center pa-3 switcher",
                        order: _ctx.order
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", _hoisted_1, [
                            (_ctx.order === 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_2, " Bon retour ! 👋 "))
                              : (_openBlock(), _createElementBlock("span", _hoisted_3, " Salut l'ami ! "))
                          ]),
                          _createElementVNode("p", _hoisted_4, [
                            (_ctx.order === 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Renseigne tes informations personnelles pour te connecter "))
                              : (_openBlock(), _createElementBlock("span", _hoisted_6, " Renseigne tes informations personnelles pour démarrer l'aventure avec nous "))
                          ]),
                          _createVNode(_component_v_btn, {
                            rounded: "xl",
                            variant: "outlined",
                            color: "white",
                            class: "mt-8 mx-5 switcher__btn",
                            onClick: _ctx.changeOrder
                          }, {
                            default: _withCtx(() => [
                              (_ctx.order === 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Se connecter "))
                                : (_openBlock(), _createElementBlock("span", _hoisted_8, " S'inscrire "))
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }, 8, ["order"]),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "9"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_form, {
                            ref: "form",
                            class: "d-flex flex-column align-center"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("h1", _hoisted_9, [
                                (_ctx.order === 0)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Créer un compte "))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_11, " Se connecter à BroPoll "))
                              ]),
                              _createVNode(_component_v_row, {
                                class: "full-width",
                                justify: "center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "6"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        modelValue: _ctx.email,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                                        rules: _ctx.emailRules,
                                        class: "mt-10",
                                        "prepend-icon": "mdi-account",
                                        placeholder: "Email",
                                        type: "input"
                                      }, null, 8, ["modelValue", "rules"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, {
                                class: "full-width",
                                justify: "center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "6"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        modelValue: _ctx.password,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                                        rules: _ctx.passwordRules,
                                        class: "mt-4",
                                        "prepend-icon": "mdi-lock",
                                        placeholder: "Mot de passe",
                                        type: "password"
                                      }, null, 8, ["modelValue", "rules"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              (_ctx.order === 0)
                                ? (_openBlock(), _createBlock(_component_v_row, {
                                    key: 0,
                                    class: "full-width",
                                    justify: "center"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        md: "6"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            modelValue: _ctx.firstName,
                                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.firstName) = $event)),
                                            class: "mt-4",
                                            "prepend-icon": "mdi-lock",
                                            placeholder: "Prénom",
                                            type: "text"
                                          }, null, 8, ["modelValue"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.order === 0)
                                ? (_openBlock(), _createBlock(_component_v_row, {
                                    key: 1,
                                    class: "full-width",
                                    justify: "center"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        md: "6"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            modelValue: _ctx.lastName,
                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lastName) = $event)),
                                            class: "mt-4",
                                            "prepend-icon": "mdi-lock",
                                            placeholder: "Nom",
                                            type: "text"
                                          }, null, 8, ["modelValue"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createVNode(_component_v_btn, {
                                disabled: _ctx.isDisabled,
                                rounded: "xl",
                                color: "blue-grey-darken-3",
                                class: "mt-8 mx-5 switcher__btn mb-8",
                                onClick: _ctx.submit
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.order === 0)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, " S'inscrire "))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_13, " Se connecter "))
                                ]),
                                _: 1
                              }, 8, ["disabled", "onClick"])
                            ]),
                            _: 1
                          }, 512),
                          (_ctx.error)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.error), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}