import { User } from '@/api/models/user'
import { Contribution } from '@/api/models/contribution'
import { Message } from '@/api/models/message'
import { Gift } from '@/api/models/gift'

export class Poll {
  id = ''
  creator: User = new User()
  updatedBy = ''
  createDate = ''
  updateDate = ''
  limitDate = ''
  name = ''
  description = ''
  photo = ''
  sumContributionsAmount = 0
  contributions: Contribution[] = []
  messages: Message[] = []
  gifts: Gift[] = []
  isOwner = false
}

export class PollPaginated {
  content: Poll[] = []
  page = 0
  size = 0
  totalElements = 0
  totalPages = 0
}

