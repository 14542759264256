import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '@/views/Login.vue'
import MyPolls from '@/views/MyPolls.vue'
import MyContributions from "@/views/MyContributions.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/my-polls',
    name: 'mypolls',
    component: MyPolls
  },
  {
    path: '/my-contributions',
    name: 'mycontributions',
    component: MyContributions
  },
  {
    path: '/poll/:id',
    name: 'poll',
    component: () => import(/* webpackChunkName: "poll" */ '@/components/PollDetails.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
