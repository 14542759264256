
import { isTablet } from '@/utils/isMobile'
import AppTitle from '@/components/AppTitle.vue'
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
  components: {
    AppTitle
  }
})
export default class HomeDescriptionBanner extends Vue {

  @Prop({
    type: String,
    default: ''
  }) itemTitle!: string

  @Prop({
    type: String,
    default: ''
  }) itemText!: string

  @Prop({
    type: String,
    required: true
  }) colorHex!: string

  @Prop({
    type: String,
    required: true
  }) imagePath!: string

  @Prop({
    type: String,
    required: false,
    default: 'first'
  }) cssIndex!: string

  @Prop({
    type: Number,
    default: 200
  }) mdHeight!: number

  @Prop({
    type: Number,
    default: 200
  }) smHeight!: number

  @Prop({
    type: Boolean,
    default: true
  }) left!: boolean

  get imgHeight(): number {
    return isTablet() ? this.smHeight : this.mdHeight
  }

  get rowHeight(): string {
    return isTablet() ? '100%' : `${this.mdHeight}px`
  }
}

