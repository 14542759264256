import { defineStore } from 'pinia'
import {ApiService} from "@/api/services/apiService";

export const useApiModuleStore = defineStore('apiModule', {
  state: () => {
    return { apiStatus: false }
  },
  actions: {
    async ping() {
      ApiService.ping().then(() => {
        this.apiStatus = true;
      }).catch(() => {
        this.apiStatus = false;
      })
    }
  }
})
