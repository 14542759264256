import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06b5ad87"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "pa-4" }
const _hoisted_3 = {
  class: "mt-10 description",
  "aria-level": "2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_app_title = _resolveComponent("app-title")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, {
    "no-gutters": "",
    style: _normalizeStyle({ backgroundColor: _ctx.colorHex, height: `${_ctx.rowHeight}px`}),
    class: _normalizeClass(["d-flex align-center", {'flex-row-reverse' : !_ctx.left}])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        md: "6",
        class: "d-flex justify-center"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(`fancy-border--${_ctx.cssIndex} my-3`)
          }, [
            _createElementVNode("img", {
              class: "img",
              src: require(`@/assets/${_ctx.imagePath}`),
              style: _normalizeStyle({ maxHeight: `${(_ctx.imgHeight - 20)}px`}),
              alt: "icon"
            }, null, 12, _hoisted_1)
          ], 2)
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, {
        cols: "12",
        md: "5",
        class: _normalizeClass(["d-flex align-center", {'justify-start': _ctx.left, 'justify-end': !_ctx.left}])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_app_title, { title: _ctx.itemTitle }, null, 8, ["title"]),
            _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.itemText), 1)
          ])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["style", "class"]))
}