

import {Component, Vue, Setup} from 'vue-facing-decorator'

import { useApiModuleStore } from '@/store/apiModule'
import {storeToRefs} from "pinia";


@Component
export default class BpFooter extends Vue {

  @Setup(() => {
    const apiModule = useApiModuleStore()
    const { apiStatus } = storeToRefs(apiModule);
    return apiStatus;
  })
  apiStatus!: boolean
}

