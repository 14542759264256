

import HomeDescriptionBanner from '@/components/home/HomeDescriptionBanner.vue'
import { Component, Vue } from 'vue-facing-decorator'

type HomeDescription = {
  itemTitle: string,
  itemText: string,
  colorHex: string,
  imagePath: string,
  mdHeight: number,
  smHeight: number,
  left: boolean,
  cssIndex: string
}

@Component({
  components: {
    HomeDescriptionBanner
  }
})
export default class Home extends Vue {

  descriptions : HomeDescription[] = []

  mounted(): void {
    this.initDescriptions()
  }

  initDescriptions(): void {
    this.descriptions.push({
      itemTitle: 'Le monde de BroPoll',
      itemText: 'L\'application web parfaite pour organiser des cadeaux communs inoubliables ! Que ce soit pour un anniversaire, un mariage ou toute autre occasion spéciale, BroPoll simplifie le processus en te permettant d\'estimer facilement la somme totale pour un cadeau commun.',
      colorHex: '#96B6C5',
      imagePath: 'home/picture_1.png',
      mdHeight: 500,
      smHeight: 300,
      left: true,
      cssIndex: 'first'
    })
    this.descriptions.push({
      itemTitle: 'Estimation des montants',
      itemText: 'Imagine que tu organises un événement spécial pour un être cher, mais tu veux connaître l\'estimation du montant que les participants seraient prêts à dépenser pour un cadeau collectif. Ne cherche plus ! Avec BroPoll, tu peux créer un sondage en ligne et l\'envoyer à tous les proches de la personne célébrée. Le concept est simple : tu proposes différentes options de montants pour le cadeau commun et les participants votent pour indiquer leur préférence. BroPoll t\'offre ainsi un aperçu clair et précis de la somme totale estimée pour le cadeau.',
      colorHex: '#ADC4CE',
      imagePath: 'home/picture_2.png',
      mdHeight: 500,
      smHeight: 300,
      left: false,
      cssIndex: 'second'
    })
    this.descriptions.push({
      itemTitle: 'Une solution pour tous',
      itemText: 'Que tu utilises BroPoll pour un anniversaire, un mariage ou une autre occasion spéciale, l\'application facilite la coordination et permet à chacun de contribuer en fonction de ses moyens et de ses préférences. Tu n\'as plus besoin de deviner ou de demander individuellement à chaque participant combien il est prêt à donner. BroPoll centralise les informations de manière anonyme pour les autres participants et présente une estimation collective sans révéler les choix individuels.',
      colorHex: '#EEE0C9',
      imagePath: 'home/picture_3.png',
      mdHeight: 500,
      smHeight: 300,
      left: true,
      cssIndex: 'third'
    })
    this.descriptions.push({
      itemTitle: 'Commence dès aujourd\'hui !',
      itemText: 'Avec BroPoll, tu peux rendre chaque occasion spéciale encore plus mémorable en créant un cadeau commun qui correspond aux préférences et aux capacités financières de chacun. C\'est le moyen idéal d\'organiser un cadeau unique et significatif en toute simplicité. Prêt à révolutionner la manière dont tu organises des cadeaux communs ? Rejoins-nous dès aujourd\'hui et utilise BroPoll pour obtenir une estimation précise du montant total pour un cadeau inoubliable !',
      colorHex: '#F1F0E8',
      imagePath: 'home/picture_4.png',
      mdHeight: 500,
      smHeight: 300,
      left: false,
      cssIndex: 'fourth'
    })
  }
}

