import axiosInstance from "@/api/services/axios-instance";
import { Auth } from '@/api/models/auth'

export abstract class AuthService {

static async login(email: string, password: string): Promise<Auth> {
    const url = `/auth/login`;
    const response = await axiosInstance.post<Auth>(url, {email, password});
    return response.data;
}

static async register(email: string, password: string, firstname: string, lastname: string): Promise<Auth> {
    const url = `/auth/register`;
    const response = await axiosInstance.post<Auth>(url, {email, password, firstname, lastname});
    return response.data;
}

}
