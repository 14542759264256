import axiosInstance from '@/api/services/axios-instance'
import { Poll, PollPaginated } from '@/api/models/poll'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()
export abstract class PollService {

  static async getAllPollsCreatedByUser(page: number, size = 2): Promise<PollPaginated> {
    const url = `/poll/mine?page=${page}&size=${size}`;
    const response = await axiosInstance.get<PollPaginated>(url);
    return response.data;
  }

  static async getAllPollsContributedByUser(page: number, size = 2): Promise<PollPaginated> {
    const url = `/poll/all?page=${page}&size=${size}`;
    const response = await axiosInstance.get<PollPaginated>(url);
    return response.data;
  }

  static async getPollById(id: string): Promise<Poll> {
    const url = `/poll/visitor/${id}`;
    const response = await axiosInstance.get<Poll>(url);
    return response.data;
  }

  static async getPollByIdWhenConnected(id: string): Promise<Poll> {
    const url = `/poll/connected/${id}`;
    const response = await axiosInstance.get<Poll>(url);
    return response.data;
  }

  static async createPoll(poll: Poll): Promise<Poll> {
    const url = `/poll`;
    const response = await axiosInstance.post<Poll>(url,poll);
    return response.data;
  }

}
